.App {
  /* text-align: center; */
}

/* App.css */
body {
  font-family: Arial, sans-serif;
}

.navbar {
  background-color: #333;
  color: white;
  padding: 15px;
  text-align: center;
}

.navbar a {
  color: white;
  padding: 14px 20px;
  text-decoration: none;
  display: inline-block;
}

.navbar a:hover {
  background-color: #ddd;
  color: black;
}

.welcome-container {
  text-align: center;
  padding: 50px;
}

.welcome-message {
  font-size: 40px;
  margin-bottom: 20px;
}

.fullwidth {
  width: 100%;
}
